import React from "react"
import PropTypes from "prop-types"
import { Container, Box, Typography, Grid } from "@mui/material"
import { Navigation, Pagination, Scrollbar, A11y } from "swiper"

import { PNScrollAnimation } from "../PNScrollAnimation"

import { Segment, Section } from "../Layout"
import { SegmentTitle } from "../SegmentTitle"
import ChaptersSwiper from "./ChaptersSwiper"

import {usePageData} from "../../hooks/usePageData"

const Chapters = ({ children }) => {
  return (
    <Segment>
      <Container disableGutters>
        <PNScrollAnimation animateIn="fadeIn">
          <Box>
            <SegmentTitle>Chapters</SegmentTitle>
          </Box>
        </PNScrollAnimation>

        <Section>
          <PNScrollAnimation animateIn="fadeIn">
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" color="secondary" align="center">
                  {usePageData().wpPage.homepage.chaptersTitle}
                </Typography>
                <Typography variant="h5" color="primary" align="center" gutter>
                  {usePageData().wpPage.homepage.chaptersSubTitle}
                </Typography>
                <Typography paragraph align="center">
                  {usePageData().wpPage.homepage.chaptersContent}
                </Typography>
              </Grid>
            </Grid>
          </PNScrollAnimation>
        </Section>
        <Box>
          <ChaptersSwiper />
        </Box>
      </Container>
    </Segment>
  )
}

Chapters.propTypes = {
  children: PropTypes.node,
}

export default Chapters
