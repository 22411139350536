import React, { useState, useEffect } from "react"
import moment from "moment"
import DateFnsUtils from "@date-io/date-fns"
import _ from "lodash"
import { rem } from "polished"
import cuid from "cuid"
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material"

import LocalizationProvider from "@mui/lab/LocalizationProvider"
import MobileDatePicker from "@mui/lab/MobileDatePicker"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import { PNScrollAnimation } from "../PNScrollAnimation"
import { Section } from "../Layout"
import { SegmentTitle } from "../SegmentTitle"
import { SectionTitle } from "../SectionTitle"

import { states, membershipOptions } from "./Join.api"

import {usePageData} from "../../hooks/usePageData"
const parse = require('html-react-parser');

const Join = ({ ...props }) => {
  const [formState, setFormState] = useState({
    membership: "new-member",
    agreement: false,
    birthday: null,
    state: "",
  })
  const bgDimensions = { height: 656, width: 12 }
  const { imgAboutHero } = useStaticQuery(
    graphql`
      query {
        imgAboutHero: file(relativePath: { eq: "images/img-join2-hero.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1280
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const image = getImage(imgAboutHero)
  const [nameError, setNameError] = useState({
    isError: false,
    errorMessage: "",
  })
  const [emailError, setEmailError] = useState({
    isError: false,
    errorMessage: "",
  })
  const [agreeError, setAgreeError] = useState({
    isError: false,
    errorMessage: "",
  })

  // Use like this:
  const bgImage = convertToBgImage(image)

  const isEmail = (email = null) => {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const validation = regex.test(email)
    return validation
  }
  const formatPhoneNumber = _phoneNumberString => {
    const cleaned = ("" + _phoneNumberString).replace(/\D/g, "")
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3]
    }
    return null
  }
  const checkNameError = _formState => {
    const { name } = _formState
    let errorState
    if (!_.isNil(name)) {
      if (name.length < 1) {
        errorState = {
          isError: true,
          errorMessage: "Please enter your name!",
        }
      } else {
        errorState = {
          isError: false,
          errorMessage: "",
        }
      }

      setNameError(prevState => ({ ...prevState, ...errorState }))
    }
  }

  const handleChange = event => {
    const objVal =
      event.target.name === "agreement"
        ? event.target.checked
        : event.target.value
    const updatedState = { [event.target.name]: objVal }
    setFormState(prevState => ({ ...prevState, ...updatedState }))
  }

  const handleChangeBirthday = date => {
    // console.log("date", date)
    const updatedState = { birthday: moment(date).format("MM/DD/YYYY") }
    setFormState(prevState => ({ ...prevState, ...updatedState }))
  }

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = event => {
    event.preventDefault()
    console.log({ formState })
    const encodedBody = encode({
      "form-name": event.target.getAttribute("name"),
      ...formState,
    })
    console.log({ encodedBody })
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encodedBody,
    })
      .then(() => navigate("/thank-you/"))
      .catch(error => alert(error))
  }

  useEffect(() => {
    const { email } = formState
    let errorState
    if (!_.isNil(email)) {
      if (isEmail(email)) {
        errorState = {
          isError: false,
          errorMessage: "",
        }
      } else {
        errorState = {
          isError: !isEmail(email),
          errorMessage: "Please enter a valid email address!",
        }
      }

      setEmailError(prevState => ({ ...prevState, ...errorState }))
    }
  }, [formState, setEmailError])

  useEffect(() => {
    checkNameError(formState)
  }, [formState])

  // console.log({ formState })

  return (
    <BackgroundImage
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      {...props}
    >
      <Box
        sx={{
          minHeight: bgDimensions.height,
          minWidth: bgDimensions.width,
          py: `${rem(64)}`,
        }}
      >
        <Container disableGutters>
          <Box>
            <PNScrollAnimation animateIn="fadeIn">
              <SegmentTitle>Join</SegmentTitle>
            </PNScrollAnimation>
          </Box>
          <Section>
            <PNScrollAnimation animateIn="fadeIn">
              <Box mb={4}>
                <SectionTitle align="center">
                  {usePageData().wpPage.homepage.joinTitle}
                </SectionTitle>
                <SectionTitle align="center" subtitle>
                  {usePageData().wpPage.homepage.joinSubTitle}
                </SectionTitle>
                <Typography align="center">
                  Please complete this fast form and you’ll be on your way to
                  dynamic growth. If you have questions, just{" "}
                  <Link
                    color="secondary"
                    href="mailto:colleen@preferred-networking.com"
                  >
                    contact our CEO, Colleen
                  </Link>
                  .
                </Typography>
              </Box>
            </PNScrollAnimation>
            <PNScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
              <form
                data-netlify="true"
                name="join-us-02-06-2022"
                method="post"
                onSubmit={handleSubmit}
              >
                <input type="hidden" name="form-name" value="join" />
                <Grid container>
                  <Grid item xs={12} md={2}></Grid>
                  <Grid item xs={12} md={8}>
                    <Box>
                      <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            name="first-name"
                            id="firstName"
                            label="First Name"
                            variant="filled"
                            fullWidth
                            color="secondary"
                            onBlur={handleChange}
                            required
                            helperText={nameError.errorMessage}
                            error={nameError.isError}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            name="last-name"
                            id="lastName"
                            label="Last Name"
                            variant="filled"
                            fullWidth
                            color="secondary"
                            onBlur={handleChange}
                            required
                            helperText={nameError.errorMessage}
                            error={nameError.isError}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} md={5}>
                          <TextField
                            id="street-address"
                            name="streetAddress"
                            label="Street Address"
                            variant="filled"
                            fullWidth
                            color="secondary"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="city"
                            name="city"
                            label="City"
                            variant="filled"
                            fullWidth
                            color="secondary"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <FormControl fullWidth variant="filled">
                            <InputLabel id="state-label">State</InputLabel>
                            <Select
                              labelId="state-label"
                              id="state"
                              name="state"
                              label="State"
                              color="secondary"
                              defaultValue=""
                              onChange={handleChange}
                            >
                              <MenuItem disabled value="">
                                <em>Select State</em>
                              </MenuItem>
                              {states.map(({ name, abbreviation }) => {
                                return (
                                  <MenuItem value={abbreviation} key={cuid()}>
                                    {name}
                                  </MenuItem>
                                )
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            id="zip"
                            name="zip"
                            label="Zip Code"
                            variant="filled"
                            fullWidth
                            color="secondary"
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            name="business-name"
                            id="businessName"
                            label="Business Name"
                            variant="filled"
                            fullWidth
                            color="secondary"
                            onBlur={handleChange}
                            required
                            helperText={nameError.errorMessage}
                            error={nameError.isError}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="business-email"
                            name="businessEmail"
                            label="Business Email"
                            variant="filled"
                            type="email"
                            fullWidth
                            color="secondary"
                            onBlur={handleChange}
                            required
                            helperText={emailError.errorMessage}
                            error={emailError.isError}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="business-type"
                            name="businessType"
                            label="Business Type"
                            variant="filled"
                            fullWidth
                            color="secondary"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="phone"
                            name="phone"
                            label="Phone"
                            variant="filled"
                            type="tel"
                            fullWidth
                            color="secondary"
                            value={formState.phone}
                            onBlur={handleChange}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="website"
                            name="website"
                            label="Website"
                            variant="filled"
                            type="text"
                            fullWidth
                            color="secondary"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="birthday"
                            name="birthday"
                            label="Birthday"
                            variant="filled"
                            fullWidth
                            color="secondary"
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{ py: 2 }}
                        columns={{ xs: 3, md: 12 }}
                      >
                        <Grid item xs={12}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              Membership Type
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-label="membership"
                              name="membership"
                              onChange={handleChange}
                              value={formState.membership}
                              required
                              error
                            >
                              {membershipOptions.map(({ label, value }) => (
                                <FormControlLabel
                                  value={value}
                                  control={<Radio color="secondary" />}
                                  label={label}
                                  key={cuid()}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} mb={2}>
                        <Grid item xs={12}>
                          <TextField
                            id="conflict-day-time"
                            name="conflictDateTime"
                            label="Conflict Days/Times"
                            variant="filled"
                            fullWidth
                            color="secondary"
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} mb={2}>
                        <Grid item xs={12}>
                          <TextField
                            id="goals-needs"
                            name="goalsNeeds"
                            label="Goals & Needs"
                            multiline
                            rows={4}
                            defaultbirthdayValue=""
                            variant="filled"
                            fullWidth
                            color="secondary"
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      <Box sx={{ my: 4 }}>
                        {parse(usePageData().wpPage.homepage.joinFooterContent)}
                      </Box>
                      <Grid container mb={4}>
                        <Grid item xs={12}>
                          <Box>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="secondary"
                                    required
                                    name="agreement"
                                    checked={formState.agreement}
                                    onChange={handleChange}
                                  />
                                }
                                label="By checking the box I certify that I am agreeing to all terms and conditions stated above. "
                              />
                            </FormGroup>
                          </Box>
                        </Grid>
                      </Grid>
                      <Box display="flex" justifyContent="center">
                        <Button
                          variant="contained"
                          size="large"
                          color="secondary"
                          sx={{ color: "white" }}
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={2}></Grid>
                </Grid>
              </form>
            </PNScrollAnimation>
          </Section>
        </Container>
      </Box>
    </BackgroundImage>
  )
}
export default Join
