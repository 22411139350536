import React from "react"
import { Grid, Typography, Container, Box } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import PNScrollAnimation from "react-animate-on-scroll"

import { SectionTitle } from "../SectionTitle"

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import {usePageData} from "../../hooks/usePageData"

const JoinCta = ({ ...props }) => {
  const bgDimensions = { height: 600, width: "100%" }

  const image = getImage(usePageData().wpPage.homepage.joinCtaBackground.localFile)

  // Use like this:
  const bgImage = convertToBgImage(image)

  return (
    <BackgroundImage
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      {...props}
    >
      <Box
        sx={{ minHeight: bgDimensions.height, minWidth: bgDimensions.width }}
      >
        <Container disableGutters>
          <Grid container>
            <Grid item xs={12} md={1}></Grid>
            <Grid item xs={12} md={10}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: bgDimensions.height,
                }}
              >
                <PNScrollAnimation animateIn="fadeInUp">
                  <Box>
                    <SectionTitle align="center" color="white">
                      {usePageData().wpPage.homepage.joinCtaTitle}
                    </SectionTitle>
                    <SectionTitle subtitle align="center" color="white">
                      {usePageData().wpPage.homepage.joinCtaSubTitle}
                    </SectionTitle>
                    <Typography align="center" color="white">
                      {usePageData().wpPage.homepage.joinCtaContent}
                    </Typography>
                  </Box>
                </PNScrollAnimation>
              </Box>
            </Grid>
            <Grid item xs={12} md={1}></Grid>
          </Grid>
        </Container>
      </Box>
    </BackgroundImage>
  )
}
export default JoinCta
