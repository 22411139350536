import React, { useState, useRef, useLayoutEffect } from "react"
import { Box } from "@mui/material"

import { About } from "../About"
import { CEO } from "../CEO"
import { Advantages } from "../Advantages"
import { Chapters } from "../Chapters"
import { ContactHero } from "../Contact"
import { Home } from "../Home"
import { Join } from "../Join"
import { Events } from "../Events"
import { JoinCta } from "../JoinCta"
import { Layout } from "../Layout"
import { Philosophy } from "../Philosophy"

const ScrollContainer = () => {
  const refHome = useRef()
  const refAbout = useRef()
  const refCeo = useRef()
  const refAdvantages = useRef()
  const refChapters = useRef()
  const refJoin = useRef()
  const refEvents = useRef()
  const refPhilosophy = useRef()
  const refContact = useRef()

  const [posHome, setPosHome] = useState(null)
  const [posAbout, setPosAbout] = useState(null)
  const [posCeo, setPosCeo] = useState(null)
  const [posAdvantages, setPosAdvantages] = useState(null)
  const [posChapters, setPosChapters] = useState(null)
  const [posJoin, setPosJoin] = useState(null)
  const [posEvents, setPosEvents] = useState(null)
  const [posPhilosophy, setPosPhilosophy] = useState(null)
  const [posContact, setPosContact] = useState(null)

  useLayoutEffect(() => {
    function updatePosition() {
      setPosHome(refHome.current.offsetTop)
      setPosAbout(refAbout.current.offsetTop)
      setPosCeo(refCeo.current.offsetTop)
      setPosAdvantages(refAdvantages.current.offsetTop)
      setPosChapters(refChapters.current.offsetTop)
      setPosJoin(refJoin.current.offsetTop)
      setPosEvents(refEvents.current.offsetTop)
      setPosPhilosophy(refPhilosophy.current.offsetTop)
      setPosContact(refContact.current.offsetTop)
    }
    window.addEventListener("resize", updatePosition)
    updatePosition()
    return () => window.removeEventListener("resize", updatePosition)
  }, [])

  return (
    <>
      <section id="home" ref={refHome}></section>
      <Layout
        yAxisHome={posHome}
        yAxisAbout={posAbout}
        yAxisCeo={posCeo}
        yAxisAdvantages={posAdvantages}
        yAxisChapters={posChapters}
        yAxisJoin={posJoin}
        yAxisEvents={posEvents}
        yAxisPhilosophy={posPhilosophy}
        yAxisContact={posContact}
      >
        <section>
          <Home />
        </section>
        <Box sx={{ position: "relative" }} ref={refAbout}>
          <Box id="about" sx={{ position: "absolute", top: "-135px" }}></Box>
          <About />
        </Box>
        <Box sx={{ position: "relative" }} ref={refCeo}>
          <Box id="ceo" sx={{ position: "absolute", top: "-135px" }}></Box>
          <CEO />
        </Box>
        <Box sx={{ position: "relative" }} ref={refAdvantages}>
          <Box
            id="advantages"
            sx={{ position: "absolute", top: "-135px" }}
          ></Box>
          <Advantages />
          <JoinCta />
        </Box>

        <Box sx={{ position: "relative" }} ref={refChapters}>
          <Box id="chapters" sx={{ position: "absolute", top: "-135px" }}></Box>
          <Chapters />
        </Box>
        <Box sx={{ position: "relative" }} ref={refJoin}>
          <Box id="join" sx={{ position: "absolute", top: "-135px" }}></Box>
          <Join />
        </Box>
        <Box sx={{ position: "relative" }} ref={refEvents}>
          <Box id="events" sx={{ position: "absolute", top: "-135px" }}></Box>
          <Events />
        </Box>

        <Box sx={{ position: "relative" }} ref={refPhilosophy}>
          <Box
            id="philosophy"
            sx={{ position: "absolute", top: "-135px" }}
          ></Box>
          <Philosophy />
        </Box>

        <Box sx={{ position: "relative" }} ref={refContact}>
          <Box id="contact"></Box>
          <ContactHero />
        </Box>
      </Layout>
    </>
  )
}
export default ScrollContainer
