import React, { useEffect } from "react"
import _ from "lodash"
import { navigate } from "gatsby"
import {
  Box,
  Typography,
  ButtonBase,
  useTheme,
  useMediaQuery,
} from "@mui/material"
import { Swiper, SwiperSlide, useSwiper } from "swiper/react"
import { Navigation, Pagination, Scrollbar, A11y } from "swiper"
// import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

import {
  StyledChapterBox,
  StyledChapterBoxCopyContainer,
  StyledOverlay,
} from "./Chapters.styled"


import {usePageData} from "../../hooks/usePageData"

const ChaptersSwiper = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))
  const handleClick = _route => {
    navigate(_route)
  }
  const chaptersdata = usePageData().wpPage.homepage.chapters;

  function convertToSlug(Text)
  {
      return Text
          .toLowerCase()
          .replace(/ /g,'-')
          .replace(/[^\w-]+/g,'')
          ;
  }

  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={32}
      slidesPerView={isSmall ? 1 : 3}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
    >
      {chaptersdata.map((chapter, idx) => {
        return (
          <SwiperSlide key={idx}>
            <ButtonBase
              onClick={() => handleClick("/chapters/" + convertToSlug(chapter.title))}
              sx={{ width: "100%" }}
              initialSlide={4}
            >
              <StyledChapterBox bgImg={chapter.image && chapter.image.localFile.publicURL}>
                <Box sx={{ position: "relative" }}>
                  <StyledChapterBoxCopyContainer>
                    <Typography variant="h5" align="center">
                      <strong> {chapter.title}</strong>
                    </Typography>
                    <Typography align="center">{chapter.copy}</Typography>
                  </StyledChapterBoxCopyContainer>
                  <StyledOverlay></StyledOverlay>
                </Box>
              </StyledChapterBox>
            </ButtonBase>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default ChaptersSwiper
